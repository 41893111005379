/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)

import '@fontsource/teko/500.css';

import '../node_modules/leaflet/dist/leaflet.css';

import './styles/app.scss';
import './styles/ams-range.css';

import '../node_modules/leaflet/dist/leaflet.js';

import './alpinejs';

/* swiperjs */
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
/* swiperjs */

// start the Stimulus application
//SLYimport './bootstrap';

const $ = require('jquery');
global.$ = global.jQuery = $;

/* Range slider */
require('ion-rangeslider/css/ion.rangeSlider.css');
require('ion-rangeslider/js/ion.rangeSlider.js');
/* Range slider */

const amsIcon = L.divIcon({className: 'ams-map-icon'});

if(document.getElementById("map-ams")) {

    let mapAms = L.map('map-ams').setView([49.82618283144765, 6.095895660734906], 14);

    L.tileLayer('https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(mapAms);


    L.marker([49.82618283144765, 6.095895660734906], {icon: amsIcon}).addTo(mapAms)
        .bindPopup('<a href="https://www.google.com/maps/place/AMS+Auto+S%C3%A0rl/@49.8261828,6.094973,18z/data=!4m12!1m6!3m5!1s0x479555c7e37074d1:0x9f9c2f10999bfc1e!2sAMS+Auto+S%C3%A0rl!8m2!3d49.8261218!4d6.0958982!3m4!1s0x479555c7e37074d1:0x9f9c2f10999bfc1e!8m2!3d49.8261218!4d6.0958982">Directions</a>');
}

/* banners */
if(document.getElementById("banner")) {
    let bannerSwiper = new Swiper('#banner', {
      spaceBetween: 0,
      effect: 'fade',
      loop: true,
      speed: 2000,
      centeredSlides: true,
      autoplay: {
          delay: 4000,
      },
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  console.log(bannerSwiper);
  if(bannerSwiper) {
    setTimeout(function(){
      bannerSwiper.update(true);
//    bannerSwiper.slideTo(0, 0);
    console.log("WTF");
    }, 100);
  }

}
/* End banners */

/* Start filters */
let filters = {};
function applyFilters() {
    console.log(filters);
            $(".car").each(function() {
                this.showIt = true;
                this.fromTos = ['fromKms', 'toKms', 'fromPrice', 'toPrice', 'fromYear', 'toYear'];
                this.froms = {'fromKms': 'toKms', 'fromPrice': 'toPrice', 'fromYear': 'toYear'};
                this.fromFields = {'fromKms': 'kms', 'fromPrice': 'price', 'fromYear': 'year'};
                this.car = $(this);
                let self = this;
                /*
                Object.entries(filters).forEach(([key, value]) => {
                    console.log(`${key}: ${value}`)
                });
                */
                $.each(filters, function(key,valueObj){
                    console.log(key + "/" + valueObj );
                    if(this.fromTos.includes(key)) {
                        if(this.froms[key]) {
                            console.log('FT::: ' +  key + ' ::: ' + this.froms[key]);
                            const value = parseInt(this.car.data(this.fromFields[key]));
                            console.log(this.fromFields[key] + ':::' + value, filters);
                            if(filters[key] <= value && filters[this.froms[key]] >= value) {
                                console.log("YESSS");
                            } else {
                                console.log("NOOOO");
                                this.showIt = false;
                            }
                        }
                    } else {
                        if (this.car.data(key) != valueObj) {
                            this.showIt = false;
                        }
                    }
                }.bind(this));

                if(this.showIt) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            })

}
if($("#filters").length > 0) {

    const ele = $("#filters");
    const minKms = parseInt(ele.data('min-kms'));
    const maxKms = parseInt(ele.data('max-kms'));
    const minPrice = parseInt(ele.data('min-price'));
    const maxPrice = parseInt(ele.data('max-price'));
    const minYear = parseInt(ele.data('min-year'));
    const maxYear = parseInt(ele.data('max-year'));

    $("#kms").ionRangeSlider({
        skin: "ams",
        type: "double",
        min: minKms,
        max: maxKms,
        from: minKms,
        to: maxKms,
        postfix: '', //'km',
        onFinish: function (data) {
            console.log(data.from, data.to);
            filters.fromKms = data.from;
            filters.toKms = data.to;
            applyFilters();
        },
    });

    $("#price").ionRangeSlider({
        skin: "ams",
        type: "double",
        min: minPrice,
        max: maxPrice,
        from: minPrice,
        to: maxPrice,
        postfix: '', //'km',
        onFinish: function (data) {
            console.log(data.from, data.to);
            filters.fromPrice = data.from;
            filters.toPrice = data.to;
            applyFilters();
        },
    });

    $("#year").ionRangeSlider({
        skin: "ams",
        type: "double",
        min: minYear,
        max: maxYear,
        from: minYear,
        to: maxYear,
        postfix: '', //'km',
        onFinish: function (data) {
            console.log(data.from, data.to);
            filters.fromYear = data.from;
            filters.toYear = data.to;
            applyFilters();
        },
    });

    $("#clearFilters").click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        $("#brand").val("");
        $("#fuel").val("");
        $("#category").val("");
        $("#gearbox").val("");
        let kmsInstance = $("#kms").data("ionRangeSlider");
        kmsInstance.update({
            min: minKms,
            max: maxKms,
            from: minKms,
            to: maxKms,
        });
        let priceInstance = $("#price").data("ionRangeSlider");
        priceInstance.update({
            min: minPrice,
            max: maxPrice,
            from: minPrice,
            to: maxPrice,
        });
        let yearInstance = $("#year").data("ionRangeSlider");
        yearInstance.update({
            min: minYear,
            max: maxYear,
            from: minYear,
            to: maxYear,
        });
        filters = {};
        applyFilters();
    });
    $("#sort").click(function(e) {
       e.preventDefault();
       e.stopPropagation();
       console.log(this.value);
       if(this.value == 'priceASC') {
           const cars = $('.car').toArray().sort(function (a, b) {
               var aVal = parseInt(a.getAttribute('data-price')),
                   bVal = parseInt(b.getAttribute('data-price'));
               console.log(aVal, bVal);
               return aVal - bVal;
           });
           $("#carList").html($(cars));
       }
        if(this.value == 'priceDESC') {
            const cars = $('.car').toArray().sort(function (a, b) {
                var aVal = parseInt(a.getAttribute('data-price')),
                    bVal = parseInt(b.getAttribute('data-price'));
                console.log(aVal, bVal);
                return bVal - aVal;
            });
            $("#carList").html($(cars));
        }
        if(this.value == 'date') {
            const cars = $('.car').toArray().sort(function (a, b) {
                var aVal = parseInt(a.getAttribute('data-registration')),
                    bVal = parseInt(b.getAttribute('data-registration'));
                console.log(aVal, bVal);
                return bVal - aVal;
            });
            $("#carList").html($(cars));
        }
    });
    $("#brand").change(function () {
        if (this.value == "") {
            //Remove from filters
            delete filters.brand;
        } else {
            //Add to filters
            filters.brand = this.value;
        }
        applyFilters();
    });
    $("#fuel").change(function () {
        if (this.value == "") {
            //Remove from filters
            delete filters.fuel;
        } else {
            //Add to filters
            filters.fuel = this.value;
        }
        applyFilters();
    });
    $("#category").change(function () {
        if (this.value == "") {
            //Remove from filters
            delete filters.category;
        } else {
            //Add to filters
            filters.category = this.value;
        }
        applyFilters();
    });
    $("#gearbox").change(function () {
        if (this.value == "") {
            //Remove from filters
            delete filters.gearbox;
        } else {
            //Add to filters
            filters.gearbox = this.value;
        }
        applyFilters();
    });
    /* Not needed as no dynamicly generated */
    /*
    $(document.body).on('change','#brand',function(){
        //alert('Change Happened');
    });
    console.log(2);
     */
}

/* End filters */